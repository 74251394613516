import { createFeatureSelector, createSelector } from '@ngrx/store';

import { getArticleForSection } from '../utils/get-article-for-section';

import { HelpCenterState } from './help-center.reducer';

export const FEATURE_KEY = 'help-center';

export const selectHelpCenterState = createFeatureSelector<HelpCenterState>(FEATURE_KEY);

export const selectArticles = createSelector(
  selectHelpCenterState,
  (state) => getArticleForSection(state.articles, state.section),
);

export const selectArticlesLoading = createSelector(
  selectHelpCenterState,
  (state) => state.articlesLoading,
);

export const selectVideos = createSelector(
  selectHelpCenterState,
  (state) => state.videos,
);

export const selectVideosLoading = createSelector(
  selectHelpCenterState,
  (state) => state.videosLoading,
);

export const selectCountries = createSelector(
  selectHelpCenterState,
  (state) => state.countries,
);

export const selectSelectedCountry = createSelector(
  selectHelpCenterState,
  ({ countries, selectedCountryCode }) => countries.find(({ countryCode }) => countryCode === selectedCountryCode) ?? countries[0],
);

export const selectArticlesIndex = createSelector(
  selectHelpCenterState,
  (state) => state.articlesIndex,
);

export const selectLanguage = createSelector(
  selectHelpCenterState,
  (state) => state.language,
);

export const selectSection = createSelector(
  selectHelpCenterState,
  (state) => state.section,
);

export const selectSearchLoading = createSelector(
  selectHelpCenterState,
  (state) => state.articlesLoading || state.articlesIndexLoading,
);

export const selectContactsLoading = createSelector(
  selectHelpCenterState,
  (state) => state.countriesLoading || state.selectedCountryCodeLoading,
);
