export enum SubscriptionPlanIdBasic {
  Basic = 1,
  Basic18 = 4,
  Basic19 = 7,
  Basic20 = 13,
}

export enum SubscriptionPlanIdBusiness {
  Business18 = 6,
  Business19 = 9,
  Business20 = 15,
  Business21 = 18,
  Business22 = 21,
  Business24 = 26,
}

export enum SubscriptionPlanIdEnterprise {
  Enterprise = 3,
  Enterprise20 = 16,
  Enterprise21 = 19,
  Enterprise22 = 22,
  Enterprise24 = 27,
  Enterprise25 = 32,
}

export enum SubscriptionPlanIdPro {
  Professional = 2,
  Pro18 = 5,
  Pro19 = 8,
  Pro20 = 14,
  Pro21 = 17,
  Pro22 = 20,
  Pro24 = 25,
}

export enum SubscriptionPlanIdTest {
  Trial = 10,
  DemoTest,
  Inner,
}

export enum SubscriptionPlanIdVault {
  Vault = 23,
}

export enum SubscriptionPlanIdPreparationArea {
  PreparationArea = 24,
}

export enum SubscriptionPlanIdCore {
  Core24 = 28,
  Core25 = 30,
}

export enum SubscriptionPlanIdPremier {
  Premier24 = 29,
  Premier25 = 31,
}

export const SubscriptionPlanId = {
  ...SubscriptionPlanIdBasic,
  ...SubscriptionPlanIdBusiness,
  ...SubscriptionPlanIdEnterprise,
  ...SubscriptionPlanIdPreparationArea,
  ...SubscriptionPlanIdPro,
  ...SubscriptionPlanIdTest,
  ...SubscriptionPlanIdVault,
  ...SubscriptionPlanIdCore,
  ...SubscriptionPlanIdPremier,
} as const;

export type SubscriptionPlanId =
  SubscriptionPlanIdBasic |
  SubscriptionPlanIdBusiness |
  SubscriptionPlanIdEnterprise |
  SubscriptionPlanIdPreparationArea |
  SubscriptionPlanIdPro |
  SubscriptionPlanIdTest |
  SubscriptionPlanIdVault |
  SubscriptionPlanIdCore |
  SubscriptionPlanIdPremier;
