import { inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { downloadFile } from '@ideals/utils/download-file';

import { CountryCodeService } from '../services/country-code';
import { HelpCenterService } from '../services/help-center';

import { helpCenterActions } from './help-center.actions';
import { HelpCenterFacade } from './help-center.facade';

export const ALL_ARTICLES_FILE_NAME = 'iDealsManual.html';

@Injectable()
export class HelpCenterEffects {
  readonly #actions$ = inject(Actions);
  readonly #countryCodeService = inject(CountryCodeService);
  readonly #helpCenterFacade = inject(HelpCenterFacade);
  readonly #helpCenterService = inject(HelpCenterService);

  readonly downloadArticles$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.downloadArticles),
    concatLatestFrom(() => this.#helpCenterFacade.language$),
    switchMap(([, language]) => this.#helpCenterService.loadAllArticlesFile(language)),
    tap((file) => {
      downloadFile(file, ALL_ARTICLES_FILE_NAME);
    }),
  ), { dispatch: false });

  readonly loadArticles$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadArticles),
    switchMap((config) => this.#helpCenterService.loadArticles(config.language)),
    map((articles) => helpCenterActions.loadArticlesSuccess({ articles })),
  ));

  readonly loadArticlesIndex$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadArticlesIndex),
    switchMap((config) => this.#helpCenterService.loadArticlesIndex(config.language)),
    map((index) => helpCenterActions.loadArticlesIndexSuccess({ index })),
  ));

  readonly loadCountries$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadCountries),
    switchMap((config) => this.#helpCenterService.loadCountries(config.language)),
    map((countries) => helpCenterActions.loadCountriesSuccess({ countries })),
  ));

  readonly loadData$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadData),
    switchMap((config) => [
      helpCenterActions.loadVideos(config),
      helpCenterActions.loadArticles(config),
      helpCenterActions.loadCountries(config),
      helpCenterActions.loadArticlesIndex(config),
      helpCenterActions.loadSelectedCountryCode(config),
    ]),
  ));

  readonly loadSelectedCountryCode$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadSelectedCountryCode),
    switchMap(() => this.#countryCodeService.getCountryCode$()),
    map((code) => helpCenterActions.loadSelectedCountryCodeSuccess({ code })),
  ));

  readonly loadVideos$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.loadVideos),
    switchMap(() => this.#helpCenterService.loadVideos()),
    map((videos) => helpCenterActions.loadVideosSuccess({ videos })),
  ));

  readonly setConfig$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.setConfig),
    concatLatestFrom(() => this.#helpCenterFacade.language$),
    filter(([config, language]) => config.language !== language),
    map(([config]) => helpCenterActions.loadData(config)),
  ));

  readonly setSelectedCountryCode$ = createEffect(() => this.#actions$.pipe(
    ofType(helpCenterActions.setSelectedCountryCode),
    tap({
      next: ({ code }) => {
        this.#countryCodeService.saveCountryCode(code);
      },
    }),
  ), { dispatch: false });
}
