import { inject } from '@angular/core';
import { getState, patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, exhaustMap, pipe, tap } from 'rxjs';

import { VOID } from '@ideals/utils/void';

import { DetailedUser, HttpError, ProjectUser, QnaSettings } from '../../models';
import { QnaService } from '../../services/qna';
import { ErrorsStore } from '../errors';
import { ProjectFacade } from '../project';

interface QnaSettingsState {
  readonly loading: boolean;
  readonly settings: QnaSettings | undefined;
}

const initialState: QnaSettingsState = {
  loading: false,
  settings: undefined,
};

const projectUserToDetailedUser = (user: ProjectUser): DetailedUser => ({
  company: user.company,
  email: user.email,
  enabled: user.isEnabled,
  firstName: user.firstName,
  groupName: user.group.name,
  id: user.id,
  isDeleted: user.isDeleted,
  lastName: user.lastName,
  phoneNumber: user.phoneNumber,
});

export const QnaSettingsStore = signalStore(
  withState(initialState),
  withMethods((store) => {
    const errorsStore = inject(ErrorsStore);
    const projectFacade = inject(ProjectFacade);
    const qnaService = inject(QnaService);

    return {
      clear: () => patchState(store, initialState),

      loadSettings: rxMethod<void>(
        pipe(
          tap(() => patchState(store, { loading: true, settings: undefined })),
          exhaustMap(() => qnaService.loadQnaSettings(projectFacade.$project()!).pipe(
            tap((settings) => patchState(store, { loading: false, settings })),
            catchError((error: HttpError) => {
              patchState(store, { loading: false });
              errorsStore.requestError(error);

              return VOID;
            }),
          )),
        ),
      ),

      updateQuestionTeamsUsersSuccess: (users: ProjectUser[], role: string, teamId: string | null) => {
        const userIds = new Set(users.map(({ id }) => id));
        const detailedUsers = users.map((user) => projectUserToDetailedUser(user));
        const settings = getState(store).settings!;

        let questionTeams = settings.workflowUsersSettings.questionTeams.map((questionTeam) => ({
          ...questionTeam,
          drafters: questionTeam.drafters!.filter((drafter) => !userIds.has(drafter.id)),
          submitters: questionTeam.submitters!.filter((submitter) => !userIds.has(submitter.id)),
        }));

        if (teamId) {
          questionTeams = questionTeams.map((questionTeam) => {
            if (questionTeam.id !== teamId) {
              return questionTeam;
            }

            switch (role) {
              case 'QuestionDrafter': {
                return {
                  ...questionTeam,
                  drafters: [...questionTeam.drafters!, ...detailedUsers],
                };
              }
              case 'QuestionSubmitter': {
                return {
                  ...questionTeam,
                  submitters: [...questionTeam.submitters!, ...detailedUsers],
                };
              }
              default:
                return questionTeam;
            }
          });
        }

        patchState(store, {
          settings: {
            ...settings,
            workflowUsersSettings: {
              ...settings.workflowUsersSettings,
              questionTeams,
            },
          },
        });
      },
    };
  }),
);
