import { EnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { ProjectEffects } from './project.effects';
import { projectReducer } from './project.reducer';
import { FEATURE_KEY } from './project.selectors';

export function provideProjectStore(): EnvironmentProviders[] {
  return [
    provideEffects([ProjectEffects]),
    provideState(FEATURE_KEY, projectReducer),
  ];
}
