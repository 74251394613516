import { createFeatureSelector, createSelector } from '@ngrx/store';

import { isProjectActive } from '../../utils/is-project-active';

import { ProjectState } from './project.reducer';

export const FEATURE_KEY = 'project';

const selectProjectState = createFeatureSelector<ProjectState>(FEATURE_KEY);

export const selectCurrentUser = createSelector(
  selectProjectState,
  (state) => state.currentUser,
);

export const selectProject = createSelector(
  selectProjectState,
  (state) => state.project,
);

export const selectActiveProject = createSelector(
  selectProject,
  (project) => project && isProjectActive(project) ? project : null,
);

export const selectProjectClosing = createSelector(
  selectProjectState,
  (state) => state.closing,
);

export const selectProjectLoading = createSelector(
  selectProjectState,
  (state) => state.loading,
);
export const selectProjectUpdating = createSelector(
  selectProjectState,
  (state) => state.updating,
);

export const selectSatisfactionSurveyLoading = createSelector(
  selectProjectState,
  (state) => state.satisfactionSurveyLoading,
);

export const selectSatisfactionSurveyVisible = createSelector(
  selectProjectState,
  (state) => state.satisfactionSurveyVisible,
);

export const selectScheduledClosureLoading = createSelector(
  selectProjectState,
  (state) => state.scheduledClosureLoading,
);

export const selectScheduledClosure = createSelector(
  selectProjectState,
  (state) => state.scheduledClosure,
);

export const selectClosureScheduling = createSelector(
  selectProjectState,
  (state) => state.closureScheduling,
);

export const selectViewAsGroup = createSelector(
  selectProjectState,
  (state) => state.viewAsGroup,
);
