import { Injectable } from '@angular/core';

import { DetailedProject } from '../../models';

@Injectable({ providedIn: 'root' })
export class DataTransporterService {
  #project: DetailedProject | null = null;

  getProject(): DetailedProject | null {
    return this.#project;
  }

  setProject(project: DetailedProject | null): void {
    this.#project = project;
  }
}
