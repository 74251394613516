import { APP_INITIALIZER, inject, Provider } from '@angular/core';

import { COVIEW_KEY_TOKEN } from './coview-key.token';
import { IntercomService } from './intercom.service';

export function provideIntercom(enabled: boolean, key: string): Provider[] {
  return enabled
    ? [
      {
        multi: true,
        provide: APP_INITIALIZER,
        useFactory: () => {
          const intercomService = inject(IntercomService);

          return () => {
            intercomService.initialize();
          };
        },
      },
      {
        provide: COVIEW_KEY_TOKEN,
        useValue: key,
      },
    ]
    : [];
}
