import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';

import { RootStoreFacade } from '@ideals/core/root-store';

import {
  DetailedProject,
  HttpErrorForbidden,
  ImmediateProjectClosure,
  ProjectGroup,
  ProjectUser,
  RequestError,
  ScheduledProjectClosure,
} from '../../models';
import { errorsActions } from '../errors';

import { projectActions } from './project.actions';
import {
  selectActiveProject,
  selectClosureScheduling,
  selectCurrentUser,
  selectProject,
  selectProjectClosing,
  selectProjectLoading,
  selectProjectUpdating,
  selectSatisfactionSurveyLoading,
  selectSatisfactionSurveyVisible,
  selectScheduledClosure,
  selectScheduledClosureLoading,
  selectViewAsGroup,
} from './project.selectors';

@Injectable({ providedIn: 'root' })
export class ProjectFacade {
  readonly #actions = inject(Actions);
  readonly #rootStoreFacade = inject(RootStoreFacade);
  readonly #store = inject(Store);

  readonly $closureScheduling = this.#store.selectSignal(selectClosureScheduling);
  readonly $currentUser = this.#store.selectSignal(selectCurrentUser);
  readonly $hostId = this.#rootStoreFacade.selectRouterParam('hostId');
  readonly $project = this.#store.selectSignal(selectActiveProject);
  readonly $projectClosing = this.#store.selectSignal(selectProjectClosing);
  readonly $projectIncludeInactive = this.#store.selectSignal(selectProject);
  readonly $projectLoading = this.#store.selectSignal(selectProjectLoading);
  readonly $projectName = this.#rootStoreFacade.selectRouterParam('projectName');
  readonly $satisfactionSurveyLoading = this.#store.selectSignal(selectSatisfactionSurveyLoading);
  readonly $satisfactionSurveyVisible = this.#store.selectSignal(selectSatisfactionSurveyVisible);
  readonly $scheduledClosure = this.#store.selectSignal(selectScheduledClosure);
  readonly $scheduledClosureLoading = this.#store.selectSignal(selectScheduledClosureLoading);
  readonly projectUpdating = this.#store.selectSignal(selectProjectUpdating);
  readonly viewAsGroup = this.#store.selectSignal(selectViewAsGroup);

  get currentProjectName$(): Observable<string | undefined> {
    return this.#rootStoreFacade.selectRouterParam$('projectName');
  }

  get currentUser$(): Observable<ProjectUser | null> {
    return this.#store.select(selectCurrentUser);
  }

  get onCancelScheduledProjectClosureFailure$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.cancelScheduledProjectClosureFailure));
  }

  get onCancelScheduledProjectClosureSuccess$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.cancelScheduledProjectClosureSuccess));
  }

  get onCloseProjectFailure$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.closeProjectFailure));
  }

  get onCloseProjectSuccess$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.closeProjectSuccess));
  }

  get onForbiddenError$(): Observable<HttpErrorForbidden> {
    return this.#actions.pipe(ofType(errorsActions.requestError)).pipe(
      filter(({ error }) => error.status === 'Forbidden' && !!(error as HttpErrorForbidden).error),
      map(({ error }) => error as HttpErrorForbidden),
    );
  }

  get onUpdateProjectFailure$(): Observable<RequestError> {
    return this.#actions.pipe(
      ofType(projectActions.updateProjectFailure),
      map(({ error }) => error)
    );
  }

  get onUpdateProjectLinkFailure$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.updateProjectLinkFailure));
  }

  get onUpdateProjectLinkSuccess$(): Observable<void> {
    return this.#actions.pipe(ofType(projectActions.updateProjectLinkSuccess));
  }

  get onUpdateProjectSuccess$(): Observable<Partial<DetailedProject>> {
    return this.#actions.pipe(
      ofType(projectActions.updateProjectSuccess),
      map(({ project }) => project)
    );
  }

  get project$(): Observable<DetailedProject | null> {
    return this.#store.select(selectActiveProject);
  }

  get projectLoading$(): Observable<boolean> {
    return this.#store.select(selectProjectLoading);
  }

  get scheduledClosure$(): Observable<ScheduledProjectClosure | null> {
    return this.#store.select(selectScheduledClosure);
  }

  cancelScheduledProjectClosure(): void {
    this.#store.dispatch(projectActions.cancelScheduledProjectClosure());
  }

  clearProject(): void {
    this.#store.dispatch(projectActions.clearProject());
  }

  closeProject(closure?: ImmediateProjectClosure): void {
    this.#store.dispatch(projectActions.closeProject(closure ? { closure } : {}));
  }

  deleteProjectLoginPageBackground(): void {
    this.#store.dispatch(projectActions.deleteProjectLoginPageBackground());
  }

  deleteProjectLogo(): void {
    this.#store.dispatch(projectActions.deleteProjectLogo());
  }

  loadProject(hostId: string, name: string, silent?: boolean): void {
    this.#store.dispatch(projectActions.loadProject({ hostId, name, silent }));
  }

  loadSatisfactionSurveyVisibility(): void {
    this.#store.dispatch(projectActions.loadSatisfactionSurveyVisibility());
  }

  loadScheduledProjectClosure(): void {
    this.#store.dispatch(projectActions.loadScheduledProjectClosure());
  }

  scheduleProjectClosure(closure: ScheduledProjectClosure): void {
    this.#store.dispatch(projectActions.scheduleProjectClosure({ closure }));
  }

  setUpdatedProject(project: Partial<DetailedProject>): void {
    this.#store.dispatch(projectActions.updateProjectSuccess({ project }));
  }

  setViewAsGroup(group?: ProjectGroup): void {
    this.#store.dispatch(projectActions.setViewAsGroup({ group }));
  }

  updateDocumentsPublishing(enabled: boolean): void {
    this.#store.dispatch(projectActions.updateProjectDocumentsPublishing({ enabled }));
  }

  updateProject(updatedProject: Partial<DetailedProject>): void {
    this.#store.dispatch(projectActions.updateProject({ updatedProject, undo: false }));
  }

  updateProjectAutoIndex(updatedProjectAutoIndex: boolean): void {
    this.#store.dispatch(projectActions.updateProjectAutoIndex({ updatedProjectAutoIndex }));
  }

  updateProjectLink(updatedProjectLink: string): void {
    this.#store.dispatch(projectActions.updateProjectLink({ updatedProjectLink, undo: false }));
  }

  updateProjectName(updatedProjectLink: string, updatedProjectName: string): void {
    this.#store.dispatch(projectActions.updateProjectName({ updatedProjectLink, updatedProjectName, undo: false }));
  }

  updateProjectSupportContacts(updatedSupportContacts: string): void {
    this.#store.dispatch(projectActions.updateProjectSupportContacts({ updatedSupportContacts, undo: false }));
  }

  updateProjectThemeColor(hoverColor: string, mainColor: string): void {
    this.#store.dispatch(projectActions.updateProjectThemeColor({ hoverColor, mainColor }));
  }

  updateProjectUserSuccess(currentUser: ProjectUser): void {
    this.#store.dispatch(projectActions.updateProjectUserSuccess({ currentUser }));
  }

  uploadProjectLoginPageBackground(file: File): void {
    this.#store.dispatch(projectActions.uploadProjectLoginPageBackground({ file }));
  }

  uploadProjectLogo(file: File): void {
    this.#store.dispatch(projectActions.uploadProjectLogo({ file }));
  }
}
