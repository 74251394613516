import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  catchError,
  delay,
  exhaustMap,
  finalize,
  first,
  map,
  of,
  skipWhile,
  Subject,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { ToastService } from '@ideals/components/toast';
import { AuthService } from '@ideals/core/auth';

import { DetailedProject, HttpError, RequestError } from '../../models';
import { ProjectUsersService } from '../../services/project-users';
import { ProjectsService } from '../../services/projects';
import { getProjectUrl } from '../../utils/get-project-url';
import { errorsActions } from '../errors';
import { projectsActions } from '../projects/projects.actions';

import { projectActions } from './project.actions';
import { ProjectFacade } from './project.facade';

export const PROJECT_CLOSURE_REDIRECT_DELAY = 3000;

@Injectable()
export class ProjectEffects {
  readonly #actions$ = inject(Actions);
  readonly #authService = inject(AuthService);
  readonly #projectFacade = inject(ProjectFacade);
  readonly #projectsService = inject(ProjectsService);
  readonly #projectUsersService = inject(ProjectUsersService);
  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #toastService = inject(ToastService);
  readonly #translateService = inject(TranslateService);

  readonly cancelScheduledProjectClosure$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.cancelScheduledProjectClosure),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([, project]) => this.#projectsService.cancelScheduledProjectClosure(project!).pipe(
      tap(() => {
        // TODO: add localization
        this.#toastService.success(this.#translateService.instant('Scheduled closure canceled') as string);
      }),
      map(() => projectActions.cancelScheduledProjectClosureSuccess()),
      catchError((error: HttpError) => of(
        errorsActions.requestError({ error }),
        projectActions.cancelScheduledProjectClosureFailure()
      )),
    )),
  ));

  readonly closeProject$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.closeProject),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ closure }, project]) => this.#projectsService.closeProject(project!, closure).pipe(
      delay(PROJECT_CLOSURE_REDIRECT_DELAY),
      tap(() => {
        const message = project!.type === 'Preparation'
          ? 'PA.Toaster.Preparation_area_closed'
          : 'archiving.Closure.Toaster.Project_closed';

        this.#toastService.success(this.#translateService.instant(message) as string);
        this.#router.navigate(['/']);
      }),
      switchMap(() => [
        projectActions.closeProjectSuccess(),
        projectActions.clearProject(),
      ]),
      catchError((error: HttpError) => of(
        errorsActions.requestError({ error }),
        projectActions.closeProjectFailure(),
      )),
    )),
  ));

  readonly deleteProjectLoginPageBackground$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.deleteProjectLoginPageBackground),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([, project]) => this.#projectsService.deleteProjectLoginPageBackground(project!).pipe(
      map(() => {
        this.#toastService.success(this.#translateService.instant('settings.Branding.Toaster.Background_deleted') as string);

        return projectActions.updateProjectSuccess({ project: { loginBackgroundUrl: '' } });
      }),
      catchError((error: HttpError) => of(errorsActions.requestError({ error }))),
    )),
  ));

  readonly deleteProjectLogo$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.deleteProjectLogo),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([, project]) => this.#projectsService.deleteProjectLogo(project!).pipe(
      switchMap(() => {
        this.#toastService.success(this.#translateService.instant('settings.Branding.Toaster.Logo_deleted') as string);

        return [
          projectActions.updateProjectSuccess({ project: { logoUrl: undefined } }),
          projectsActions.setUpdatedProject({ name: project!.name, updatedProject: { logoUrl: undefined } }),
        ];
      }),
      catchError((error: HttpError) => of(errorsActions.requestError({ error }))),
    )),
  ));

  readonly loadProject$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.loadProject),
    switchMap(({ hostId, name }) => this.#projectsService.loadProject(hostId!, name).pipe(
      tap((project) => {
        if (!this.#authService.user.roomAccess.includes(project.id)) {
          this.#authService.refreshToken();
        }
      }),
      switchMap((project) => this.#projectUsersService.loadCurrentUser(project).pipe(
        map((currentUser) => {
          return projectActions.loadProjectSuccess({ currentUser, project });
        }),
      )),
      catchError((error: RequestError) => {
        return of(
          projectActions.loadProjectFailure({ error }),
          errorsActions.requestError({ error }),
        );
      }),
    )),
  ));

  readonly loadSatisfactionSurveyVisibility$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.loadSatisfactionSurveyVisibility),
    switchMap(() => this.#projectFacade.project$.pipe(
      first(Boolean)
    )),
    switchMap((project) => this.#projectsService.loadSatisfactionSurveyVisibility(project!).pipe(
      map((visible) => projectActions.loadSatisfactionSurveyVisibilitySuccess({ visible })),
      catchError((error: HttpError) => of(
        projectActions.loadSatisfactionSurveyVisibilitySuccess({ visible: false }),
        errorsActions.requestError({ error })
      )),
    )),
  ));

  readonly loadScheduledProjectClosure$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.loadScheduledProjectClosure),
    switchMap(() => this.#projectFacade.project$.pipe(
      first(Boolean)
    )),
    switchMap((project) => this.#projectsService.loadScheduledProjectClosure(project).pipe(
      map((closure) => projectActions.loadScheduledProjectClosureSuccess({ closure })),
      catchError((error: HttpError) => of(
        errorsActions.requestError({ error }),
        projectActions.loadScheduledProjectClosureFailure()
      )),
    )),
  ));

  readonly scheduleProjectClosure$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.scheduleProjectClosure),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ closure }, project]) => this.#projectsService.scheduleProjectClosure(project!, closure).pipe(
      tap(() => {
        this.#toastService.success(this.#translateService.instant('archiving.Closure.Toaster.Project_closure_scheduled') as string);
        this.#router.navigateByUrl(`${getProjectUrl(project!.hostId, project!.name)}/archiving`);
      }),
      map(() => projectActions.scheduleProjectClosureSuccess({ closure })),
      catchError((error: HttpError) => of(
        errorsActions.requestError({ error }),
        projectActions.scheduleProjectClosureFailure(),
      )),
    )),
  ));

  readonly setViewAsGroup$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.setViewAsGroup),
    tap(({ group }) => {
      if (!group) {
        this.#toastService.info(this.#translateService.instant('common.TOASTER.View_as_closed') as string);
      }
    }),
  ), { dispatch: false });

  readonly updateProject$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProject),
    withLatestFrom(this.#projectFacade.project$),
    exhaustMap(([{ undo, updatedProject }, project]) => {
      return this.#projectsService.updateProject({ ...project!, ...updatedProject }).pipe(
        switchMap(() => {
          if (undo) {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Action_undone') as string);
          } else {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Settings_updated') as string, {
              buttons: [{
                command: () => {
                  this.#store.dispatch(projectActions.updateProject({ updatedProject: project!, undo: true }));
                },
                shortcut: 'ctrl+z',
                label: this.#translateService.instant('common.BTN.undo') as string,
              }],
            });
          }

          this.#navigateToUpdatedProject({ ...project!, ...updatedProject });

          return [
            projectActions.updateProjectSuccess({ project: updatedProject }),
            projectActions.updateProjectLinkSuccess(),
            projectsActions.setUpdatedProject({ name: project!.name, updatedProject }),
          ];
        }),
        catchError((error: RequestError) => of(
          projectActions.updateProjectFailure({ error }),
          errorsActions.requestError({ error }),
        )),
      );
    }),
  ));

  readonly updateProjectAutoIndex$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectAutoIndex),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ updatedProjectAutoIndex }, project]) => this.#projectsService.updateProject({
      ...project!,
      autoIndexEnabled: updatedProjectAutoIndex,
    }).pipe(
      map(({ autoIndexEnabled }) => {
        const message = updatedProjectAutoIndex
          ? 'common.Toaster.Automatic_indexing_enabled'
          : 'common.Toaster.Automatic_indexing_disabled';

        this.#toastService.success(this.#translateService.instant(message) as string);

        return projectActions.updateProjectSuccess({ project: { autoIndexEnabled } });
      }),
      catchError((error: RequestError) => of(
        projectActions.updateProjectFailure({ error }),
        errorsActions.requestError({ error }),
      )),
    )),
  ));

  readonly updateProjectDocumentPublishing$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectDocumentsPublishing),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ enabled }, project]) => this.#projectsService.updateProject({
      ...project!,
      documentsPublishingEnabled: enabled,
    }).pipe(
      map(({ documentsPublishingEnabled }) => {
        const message = documentsPublishingEnabled
          ? 'settings.Documents.Toaster.Publishing_enabled'
          : 'settings.Documents.Toaster.Publishing_disabled';

        this.#toastService.success(this.#translateService.instant(message) as string);

        return projectActions.updateProjectSuccess({ project: { documentsPublishingEnabled } });
      }),
      catchError((error: RequestError) => of(
        projectActions.updateProjectFailure({ error }),
        errorsActions.requestError({ error }),
      )),
    )),
  ));

  readonly updateProjectLink$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectLink),
    withLatestFrom(this.#projectFacade.project$),
    exhaustMap(([{ undo, updatedProjectLink }, project]) => {
      return this.#projectsService.updateProject({ ...project!, name: updatedProjectLink }).pipe(
        switchMap((updatedProject) => {
          if (undo) {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Action_undone') as string);
          } else {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Project_link_updated') as string, {
              buttons: [{
                command: () => {
                  this.#store.dispatch(projectActions.updateProjectLink({ updatedProjectLink: project!.name, undo: true }));
                },
                shortcut: 'ctrl+z',
                label: this.#translateService.instant('common.BTN.undo') as string,
              }],
            });
          }

          this.#navigateToUpdatedProject(updatedProject);

          return [
            projectActions.updateProjectSuccess({ project: { name: updatedProject.name } }),
            projectActions.updateProjectLinkSuccess(),
            projectsActions.setUpdatedProject({ name: project!.name, updatedProject: { name: updatedProject.name } }),
          ];
        }),
        catchError((error: RequestError) => of(
          projectActions.updateProjectFailure({ error }),
          projectActions.updateProjectLinkFailure(),
          errorsActions.requestError({ error }),
        )),
      );
    }),
  ));

  readonly updateProjectName$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectName),
    withLatestFrom(this.#projectFacade.project$),
    exhaustMap(([{ undo, updatedProjectLink, updatedProjectName }, project]) => {
      return this.#projectsService.updateProject({ ...project!, name: updatedProjectLink, title: updatedProjectName }).pipe(
        switchMap((updatedProject) => {
          if (undo) {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Action_undone') as string);
          } else {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Project_renamed') as string, {
              buttons: [{
                command: () => {
                  this.#store.dispatch(projectActions.updateProjectName({
                    updatedProjectLink: project!.name,
                    updatedProjectName: project!.title,
                    undo: true,
                  }));
                },
                shortcut: 'ctrl+z',
                label: this.#translateService.instant('common.BTN.undo') as string,
              }],
            });
          }

          this.#navigateToUpdatedProject(updatedProject);

          return [
            projectActions.updateProjectSuccess({ project: { name: updatedProject.name, title: updatedProject.title } }),
            projectActions.updateProjectLinkSuccess(),
            projectsActions.setUpdatedProject({
              name: project!.name,
              updatedProject: { name: updatedProject.name, title: updatedProject.title },
            }),
          ];
        }),
        catchError((error: RequestError) => of(
          projectActions.updateProjectFailure({ error }),
          errorsActions.requestError({ error }),
        )),
      );
    }),
  ));

  readonly updateProjectSupportContacts$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectSupportContacts),
    withLatestFrom(this.#projectFacade.project$),
    exhaustMap(([{ undo, updatedSupportContacts }, project]) => {
      return this.#projectsService.updateProject({ ...project!, supportContacts: updatedSupportContacts }).pipe(
        map(({ supportContacts }) => {
          if (undo) {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Action_undone') as string);
          } else {
            this.#toastService.success(this.#translateService.instant('common.Toaster.Administrator\'s_contact_updated') as string, {
              buttons: [{
                command: () => {
                  this.#store.dispatch(projectActions.updateProjectSupportContacts({
                    updatedSupportContacts: project!.supportContacts,
                    undo: true,
                  }));
                },
                shortcut: 'ctrl+z',
                label: this.#translateService.instant('common.BTN.undo') as string,
              }],
            });
          }

          return projectActions.updateProjectSuccess({ project: { supportContacts } });
        }),
        catchError((error: RequestError) => of(
          projectActions.updateProjectFailure({ error }),
          errorsActions.requestError({ error }),
        )),
      );
    }),
  ));

  readonly updateProjectThemeColor$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.updateProjectThemeColor),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ hoverColor, mainColor }, project]) => this.#projectsService.updateColorTheme(project!, hoverColor, mainColor).pipe(
      switchMap(() => {
        this.#toastService.success(this.#translateService.instant('settings.Branding.Toaster.Theme_color_updated') as string);

        return [
          projectActions.updateProjectSuccess({ project: { mainColor } }),
          projectsActions.setUpdatedProject({ name: project!.name, updatedProject: { mainColor } }),
        ];
      }),
      catchError((error: RequestError) => of(
        projectActions.updateProjectFailure({ error }),
        errorsActions.requestError({ error }),
      )),
    )),
  ));

  readonly uploadProjectLoginPageBackground$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.uploadProjectLoginPageBackground),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ file }, project]) => {
      const cancel$ = new Subject<void>();
      const progress$ = new Subject<number>();

      const toastRef = this.#toastService.progress(
        this.#translateService.instant('settings.Branding.Toaster.Background_uploading') as string,
        {
          buttons: [{
            command: () => cancel$.next(),
            label: this.#translateService.instant('common.BTN.cancel') as string,
          }],
          progress$,
          sticky: true,
        },
      );

      return this.#projectsService.uploadProjectLoginPageBackground(project!, file).pipe(
        tap((uploadEvent) => {
          progress$.next(uploadEvent.progress);
        }),
        skipWhile((uploadEvent) => !uploadEvent.response),
        map(() => {
          this.#toastService.success(this.#translateService.instant('settings.Branding.Toaster.Background_changed') as string);

          return projectActions.loadProject({ hostId: project!.hostId, name: project!.name });
        }),
        catchError((error: HttpError) => of(errorsActions.requestError({ error }))),
        takeUntil(cancel$),
        finalize(() => {
          toastRef.close();
          cancel$.complete();
          progress$.complete();
        }),
      );
    }),
  ));

  readonly uploadProjectLogo$ = createEffect(() => this.#actions$.pipe(
    ofType(projectActions.uploadProjectLogo),
    withLatestFrom(this.#projectFacade.project$),
    switchMap(([{ file }, project]) => {
      const cancel$ = new Subject<void>();
      const progress$ = new Subject<number>();

      const toastRef = this.#toastService.progress(this.#translateService.instant('settings.Branding.Toaster.Logo_uploading') as string, {
        buttons: [{
          command: () => cancel$.next(),
          label: this.#translateService.instant('common.BTN.cancel') as string,
        }],
        progress$,
        sticky: true,
      });

      return this.#projectsService.uploadProjectLogo(project!, file).pipe(
        tap((uploadEvent) => {
          progress$.next(uploadEvent.progress);
        }),
        skipWhile((uploadEvent) => !uploadEvent.response),
        switchMap(() => {
          this.#toastService.success(this.#translateService.instant('settings.Branding.Toaster.Logo_updated') as string);

          this.#store.dispatch(projectActions.loadProject({ hostId: project!.hostId, name: project!.name }));

          return this.#projectFacade.project$
            .pipe(
              first(Boolean),
              map((updatedProject) => projectsActions.setUpdatedProject({
                name: project!.name,
                updatedProject: { logoUrl: updatedProject!.logoUrl },
              })),
            );
        }),
        catchError((error: HttpError) => of(errorsActions.requestError({ error }))),
        takeUntil(cancel$),
        finalize(() => {
          toastRef.close();
          cancel$.complete();
          progress$.complete();
        }),
      );
    }),
  ));

  #navigateToUpdatedProject(updatedProject: DetailedProject): void {
    this.#router.navigate(['/project', updatedProject.hostId, updatedProject.name, 'settings', 'project']);
  }
}
