import { ChangeDetectionStrategy, Component, effect, inject, Injector, isDevMode, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import posthog from 'posthog-js';
import { filter } from 'rxjs';

import { ConfirmDialogComponent } from '@ideals/components/confirm-dialog';
import { ToastComponent } from '@ideals/components/toast';
import { AuthService } from '@ideals/core/auth';
import { ClarityService } from '@ideals/core/clarity';
import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { HeapService } from '@ideals/core/heap';
import { LocalStorageService } from '@ideals/services/local-storage';

import { HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY, HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY } from './data/models';
import { OperationTrackerService } from './data/services/operation-tracker';
import { AuthUserStore } from './data/store/auth-user';
import { getPermissionsUpdateCountKey } from './data/utils/get-permissions-update-count-key';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ideals-app-root',
  },
  imports: [
    ConfirmDialogComponent,
    RouterOutlet,
    ToastComponent,
  ],
  selector: 'vdr-root',
  standalone: true,
  templateUrl: './root.container.html',
})
export class RootContainer implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #authUserStore = inject(AuthUserStore);
  readonly #clarityService = inject(ClarityService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #heapService = inject(HeapService);
  readonly #injector = inject(Injector);
  readonly #localStorageService = inject(LocalStorageService);
  readonly #operationTrackerService = inject(OperationTrackerService);

  ngOnInit(): void {
    if (this.#authService.isLoggedIn && !this.#authService.hasRole('guest')) {
      this.#authUserStore.loadAuthUser();
    }

    // Temporary hardcoded solution.
    // PM will test it in the QA environment, and later in PROD.
    // If the service is approved by the PM, a proper service for PostHog should be implemented
    // otherwise, this code should be removed entirely.
    if (this.#featureFlagsService.isEnabled('fvdr-posthog-enabled') && !isDevMode()) {
      posthog.init('phc_H8PWrEme9bPLnAXigQZCvckTEd6zLcYKsqSBAoqEs3y', {
        // eslint-disable-next-line camelcase
        api_host: 'https://eu.i.posthog.com',
        // eslint-disable-next-line camelcase
        person_profiles: 'identified_only',
      });
    }

    this.#authService.events$
      .pipe(filter((event) => ['logout', 'session_error'].includes(event.type)))
      .subscribe(() => {
        this.#localStorageService.removeItem(HIDE_CONTRACT_EXPIRES_NOTIFICATION_KEY);
        this.#localStorageService.removeItem(HIDE_OUTSTANDING_BALANCE_NOTIFICATION_KEY);

        this.#operationTrackerService.reset('vdr-documents-operations-count');
      });

    this.#effects();
  }

  #effects(): void {
    effect(() => {
      const authUser = this.#authUserStore.user();

      if (authUser) {
        this.#heapService.identify(authUser.id);
        this.#clarityService.identify(authUser.id);

        this.#operationTrackerService.setup({
          'vdr-documents-operations-count': 'number_of_move_copy_restore_operations',
          [getPermissionsUpdateCountKey(authUser.id)]: 'apply_permission_count_admin',
        });
      }
    }, { allowSignalWrites: true, injector: this.#injector });
  }
}
