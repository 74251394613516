import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  DetailedProject,
  ImmediateProjectClosure,
  ProjectGroup,
  ProjectUser,
  RequestError,
  ScheduledProjectClosure,
} from '../../models';
import { FEATURE_KEY } from '../vdr.selectors';

export const projectActions = createActionGroup({
  source: FEATURE_KEY,
  events: {
    cancelScheduledProjectClosure: emptyProps(),
    cancelScheduledProjectClosureFailure: emptyProps(),
    cancelScheduledProjectClosureSuccess: emptyProps(),
    clearProject: emptyProps(),
    closeProject: props<{ closure?: ImmediateProjectClosure; }>(),
    closeProjectFailure: emptyProps(),
    closeProjectSuccess: emptyProps(),
    deleteProjectLoginPageBackground: emptyProps(),
    deleteProjectLogo: emptyProps(),
    loadSatisfactionSurveyVisibility: emptyProps(),
    loadSatisfactionSurveyVisibilitySuccess: props<{ visible: boolean; }>(),
    loadProject: props<{ hostId: string; name: string; silent?: boolean; }>(),
    loadProjectFailure: props<{ error: RequestError; }>(),
    loadProjectSuccess: props<{
      currentUser: ProjectUser;
      project: DetailedProject;
    }>(),
    loadScheduledProjectClosure: emptyProps(),
    loadScheduledProjectClosureFailure: emptyProps(),
    loadScheduledProjectClosureSuccess: props<{ closure: ScheduledProjectClosure | null; }>(),
    scheduleProjectClosure: props<{ closure: ScheduledProjectClosure; }>(),
    scheduleProjectClosureFailure: emptyProps(),
    scheduleProjectClosureSuccess: props<{ closure: ScheduledProjectClosure; }>(),
    setViewAsGroup: props<{ group?: ProjectGroup; }>(),
    updateProject: props<{ undo: boolean; updatedProject: Partial<DetailedProject>; }>(),
    updateProjectAutoIndex: props<{ updatedProjectAutoIndex: boolean; }>(),
    updateProjectDocumentsPublishing: props<{ enabled: boolean; }>(),
    updateProjectLink: props<{ undo: boolean; updatedProjectLink: string; }>(),
    updateProjectLinkFailure: emptyProps(),
    updateProjectLinkSuccess: emptyProps(),
    updateProjectName: props<{ undo: boolean; updatedProjectLink: string; updatedProjectName: string; }>(),
    updateProjectUserSuccess: props<{ currentUser: ProjectUser; }>(),
    updateProjectFailure: props<{ error: RequestError; }>(),
    updateProjectSuccess: props<{ project: Partial<DetailedProject>; }>(),
    updateProjectSupportContacts: props<{ undo: boolean; updatedSupportContacts: string; }>(),
    updateProjectThemeColor: props<{ hoverColor: string; mainColor: string; }>(),
    uploadProjectLoginPageBackground: props<{ file: File; }>(),
    uploadProjectLogo: props<{ file: File; }>(),
  },
});
