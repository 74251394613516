import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Article, Country, HelpCenterConfig, HelpCenterSection, SearchArticle, Video } from '../models';

import { helpCenterActions } from './help-center.actions';
import {
  selectArticles,
  selectArticlesIndex,
  selectArticlesLoading,
  selectContactsLoading,
  selectCountries,
  selectLanguage,
  selectSearchLoading,
  selectSection,
  selectSelectedCountry,
  selectVideos,
  selectVideosLoading,
} from './help-center.selectors';

@Injectable({ providedIn: 'root' })
export class HelpCenterFacade {
  readonly #store = inject(Store);

  get articles$(): Observable<Article[]> {
    return this.#store.select(selectArticles);
  }

  get articlesIndex$(): Observable<SearchArticle[]> {
    return this.#store.select(selectArticlesIndex);
  }

  get articlesLoading$(): Observable<boolean> {
    return this.#store.select(selectArticlesLoading);
  }

  get contactsLoading$(): Observable<boolean> {
    return this.#store.select(selectContactsLoading);
  }

  get countries$(): Observable<Country[]> {
    return this.#store.select(selectCountries);
  }

  get language$(): Observable<string> {
    return this.#store.select(selectLanguage);
  }

  get searchLoading$(): Observable<boolean> {
    return this.#store.select(selectSearchLoading);
  }

  get section$(): Observable<HelpCenterSection> {
    return this.#store.select(selectSection);
  }

  get selectedCountry$(): Observable<Country | null> {
    return this.#store.select(selectSelectedCountry);
  }

  get videos$(): Observable<Video[]> {
    return this.#store.select(selectVideos);
  }

  get videosLoading$(): Observable<boolean> {
    return this.#store.select(selectVideosLoading);
  }

  downloadArticles(): void {
    this.#store.dispatch(helpCenterActions.downloadArticles());
  }

  setConfig(config: HelpCenterConfig): void {
    this.#store.dispatch(helpCenterActions.setConfig(config));
  }

  setSelectedCountryCode(code: string): void {
    this.#store.dispatch(helpCenterActions.setSelectedCountryCode({ code }));
  }
}
