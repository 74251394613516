import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Article, Country, HelpCenterConfig, SearchArticle, Video } from '../models';

import { FEATURE_KEY } from './help-center.selectors';

export const helpCenterActions = createActionGroup({
  source: FEATURE_KEY,
  events: {
    downloadArticles: emptyProps(),
    loadArticlesIndexSuccess: props<{ index: SearchArticle[]; }>(),
    loadArticlesIndex: props<HelpCenterConfig>(),
    loadArticlesSuccess: props<{ articles: Article[]; }>(),
    loadArticles: props<HelpCenterConfig>(),
    loadCountriesSuccess: props<{ countries: Country[]; }>(),
    loadCountries: props<HelpCenterConfig>(),
    loadData: props<HelpCenterConfig>(),
    loadSelectedCountryCodeSuccess: props<{ code: string; }>(),
    loadSelectedCountryCode: props<HelpCenterConfig>(),
    loadVideosSuccess: props<{ videos: Video[]; }>(),
    loadVideos: props<HelpCenterConfig>(),
    setConfig: props<HelpCenterConfig>(),
    setSelectedCountryCode: props<{ code: string; }>(),
  },
});
