import { createReducer, on } from '@ngrx/store';

import { Article, Country, HelpCenterConfig, SearchArticle, Video } from '../models';
import { DEFAULT_COUNTRY_CODE } from '../services/country-code';

import { helpCenterActions } from './help-center.actions';

export interface HelpCenterState extends HelpCenterConfig {
  readonly articles: Article[];
  readonly articlesIndex: SearchArticle[];
  readonly articlesIndexLoading: boolean;
  readonly articlesLoading: boolean;
  readonly countries: Country[];
  readonly countriesLoading: boolean;
  readonly selectedCountryCode: string;
  readonly selectedCountryCodeLoading: boolean;
  readonly videos: Video[];
  readonly videosLoading: boolean;
}

const initialHelpCenterState: HelpCenterState = {
  articles: [],
  articlesIndex: [],
  articlesIndexLoading: false,
  articlesLoading: false,
  countries: [],
  countriesLoading: false,
  language: '',
  section: '',
  selectedCountryCode: DEFAULT_COUNTRY_CODE,
  selectedCountryCodeLoading: false,
  videos: [],
  videosLoading: false,
};

export const helpCenterReducer = createReducer(
  initialHelpCenterState,
  on(helpCenterActions.setConfig, (state, { section }) => ({
    ...state,
    section,
  })),
  on(helpCenterActions.loadData, (state, { language }) => ({
    ...state,
    language,
  })),
  on(helpCenterActions.loadArticles, (state) => ({
    ...state,
    articlesLoading: true,
  })),
  on(helpCenterActions.loadArticlesSuccess, (state, { articles }) => ({
    ...state,
    articles,
    articlesLoading: false,
  })),
  on(helpCenterActions.loadVideos, (state) => ({
    ...state,
    videosLoading: true,
  })),
  on(helpCenterActions.loadVideosSuccess, (state, { videos }) => ({
    ...state,
    videos,
    videosLoading: false,
  })),
  on(helpCenterActions.loadCountries, (state) => ({
    ...state,
    countriesLoading: true,
  })),
  on(helpCenterActions.loadCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
    countriesLoading: false,
  })),
  on(helpCenterActions.loadArticlesIndex, (state) => ({
    ...state,
    articlesIndexLoading: true,
  })),
  on(helpCenterActions.loadArticlesIndexSuccess, (state, { index }) => ({
    ...state,
    articlesIndex: index,
    articlesIndexLoading: false,
  })),
  on(helpCenterActions.loadSelectedCountryCode, (state) => ({
    ...state,
    selectedCountryCodeLoading: true,
  })),
  on(helpCenterActions.loadSelectedCountryCodeSuccess, (state, { code }) => ({
    ...state,
    selectedCountryCode: code,
    selectedCountryCodeLoading: false,
  })),
  on(helpCenterActions.setSelectedCountryCode, (state, { code }) => ({
    ...state,
    selectedCountryCode: code,
  })),
);
