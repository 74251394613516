import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { FEATURE_KEY, HelpCenterEffects, helpCenterReducer } from './store';

export const HELP_CENTER_API_URL = new InjectionToken<string>('HELP_CENTER_API_URL');

export function provideHelpCenter(apiUrl: string): (Provider | EnvironmentProviders)[] {
  return [
    provideEffects([HelpCenterEffects]),
    provideState(FEATURE_KEY, helpCenterReducer),
    {
      provide: HELP_CENTER_API_URL,
      useValue: apiUrl,
    },
  ];
}
