import { createActionGroup, emptyProps, props } from '@ngrx/store';

import {
  AdministratedAccounts,
  CreatePreparationArea,
  CreateProject,
  DetailedProject,
  HttpError,
  ListProject,
  ProjectBase,
  ProjectServers,
  ProjectType,
  ProjectUseCase,
} from '../../models';
import { FEATURE_KEY } from '../vdr.selectors';

export const projectsActions = createActionGroup({
  source: FEATURE_KEY,
  events: {
    createPreparationArea: props<{ preparationArea: CreatePreparationArea; }>(),
    createPreparationAreaFailure: props<{ error: HttpError; }>(),
    createPreparationAreaSuccess: props<{ project: ProjectBase; }>(),
    createProject: props<{ project: CreateProject; }>(),
    createProjectFailure: props<{ error: HttpError; }>(),
    createProjectSuccess: props<{ hostId: string; name: string; newIdealsSwitchEnabled: boolean; projectType: ProjectType; }>(),
    createTrialProject: props<{ name: string; sourceProject: ProjectBase; }>(),
    createTrialProjectFailure: props<{ error: HttpError; }>(),
    createTrialProjectSuccess: props<{ detailedProject: DetailedProject; listProject: ListProject; }>(),
    loadAdministratedAccounts: emptyProps(),
    loadAdministratedAccountsFailure: emptyProps(),
    loadAdministratedAccountsSuccess: props<{ administratedAccounts: AdministratedAccounts; }>(),
    loadAdministratedProjects: emptyProps(),
    loadAdministratedProjectsSuccess: props<{ administratedProjects: ListProject[]; }>(),
    loadProjects: emptyProps(),
    loadProjectsFailure: props<{ error: HttpError; }>(),
    loadProjectsSuccess: props<{ projects: ListProject[]; }>(),
    loadServers: props<{ accountIds?: string[]; }>(),
    loadServersFailure: props<{ error: HttpError; }>(),
    loadServersSuccess: props<{ servers: ProjectServers; }>(),
    loadUseCases: emptyProps(),
    loadUseCasesFailure: emptyProps(),
    loadUseCasesSuccess: props<{ useCases: ProjectUseCase[]; }>(),
    setUpdatedProject: props<{ name: string; updatedProject: Partial<ProjectBase>; }>(),
    updateFavoriteProject: props<{ isFavorite: boolean; project: ListProject; }>(),
    updateFavoriteProjectSuccess: props<{ project: ListProject; }>(),
  },
});
