import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, isDevMode, Provider } from '@angular/core';
import StackTrace from 'stacktrace-js';

import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { PlatformService } from '@ideals/core/platform';

import { DetailedProject } from '../../models';
import { DataTransporterService } from '../../services/data-transporter';

interface ErrorReport {
  Application: 'app-frontend';
  Browser: string;
  ClientType: string;
  Exception: string;
  HostId: string;
  Level: 'error';
  Message: string;
  Os: string;
  RoomId: string;
  RoomName: string;
  Url: string;
}

export class CustomErrorHandler implements ErrorHandler {
  readonly #dataTransporterService = inject(DataTransporterService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #httpClient = inject(HttpClient);
  readonly #platformService = inject(PlatformService);

  handleError(error: Error | HttpErrorResponse): void {
    const chunkFailedMessage = /Loading chunk [\w-]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      console.error(error);
    }

    if (error instanceof HttpErrorResponse) {
      return;
    }

    if (this.#featureFlagsService.isEnabled('fvdr-logger-service-enabled') && !isDevMode() && error instanceof Error) {
      StackTrace.fromError(error)
        .then((frames) => {
          const errorReport: ErrorReport = {
            Application: 'app-frontend',
            Browser: this.#platformService.deviceInfo.browser,
            ClientType: this.#getClientType(),
            Exception: frames.map((frame) => frame.toString())[0] ?? '',
            HostId: this.#getProject()?.hostId ?? '',
            Level: 'error',
            Message: error.message,
            Os: this.#platformService.deviceInfo.os,
            RoomId: this.#getProject()?.id ?? '',
            RoomName: this.#getProject()?.name ?? '',
            Url: window.location.href,
          };

          this.#httpClient.post('/api/v1/log', errorReport).subscribe();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  #getClientType(): string {
    if (this.#platformService.platformType() === 'mobile') {
      return this.#platformService.isStandalone() ? 'mobile app' : 'mobile';
    }

    return this.#platformService.platformType();
  }

  #getProject(): DetailedProject | null {
    return this.#dataTransporterService.getProject();
  }
}

export function provideErrorHandler(): Provider[] {
  return [{
    provide: ErrorHandler,
    useClass: CustomErrorHandler,
  }];
}
