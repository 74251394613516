import { filter } from 'rxjs';

import { ToastComponent, ToastMessage } from './toast.component';

export class ToastRef {
  readonly closed$ = this.component.closed.pipe(
    filter((message) => message === this.message)
  );

  constructor(
    protected component: ToastComponent,
    protected message: ToastMessage,
  ) {}

  close(): void {
    this.component.close(this.message);
  }
}
