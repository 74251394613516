import { createReducer, on } from '@ngrx/store';

import { DetailedProject, ProjectGroup, ProjectUser, RequestError, ScheduledProjectClosure } from '../../models';

import { projectActions } from './project.actions';

export interface ProjectState {
  readonly closing: boolean;
  readonly closureScheduling: boolean;
  readonly currentUser: ProjectUser | null;
  readonly loading: boolean;
  readonly project: DetailedProject | null;
  readonly satisfactionSurveyLoading: boolean;
  readonly satisfactionSurveyVisible: boolean;
  readonly scheduledClosure: ScheduledProjectClosure | null;
  readonly scheduledClosureLoading: boolean;
  readonly updateProjectError: RequestError | null;
  readonly updating: boolean;
  readonly viewAsGroup?: ProjectGroup;
}

export const initialProjectState: ProjectState = {
  closing: false,
  closureScheduling: false,
  currentUser: null,
  loading: true,
  project: null,
  satisfactionSurveyLoading: false,
  satisfactionSurveyVisible: false,
  scheduledClosure: null,
  scheduledClosureLoading: true,
  updateProjectError: null,
  updating: false,
};

export const projectReducer = createReducer<ProjectState>(
  initialProjectState,
  on(projectActions.clearProject, () => initialProjectState),
  on(projectActions.closeProject, (state) => ({
    ...state,
    closing: true,
  })),
  on(
    projectActions.closeProjectFailure,
    projectActions.closeProjectSuccess,
    (state) => ({
      ...state,
      closing: false,
    })
  ),
  on(projectActions.cancelScheduledProjectClosureSuccess, (state) => ({
    ...state,
    scheduledClosure: null,
  })),
  on(projectActions.loadScheduledProjectClosure, (state) => ({
    ...state,
    scheduledClosureLoading: true,
  })),
  on(projectActions.loadScheduledProjectClosureFailure, (state) => ({
    ...state,
    scheduledClosureLoading: false,
  })),
  on(projectActions.loadScheduledProjectClosureSuccess, (state, { closure }) => ({
    ...state,
    scheduledClosure: closure,
    scheduledClosureLoading: false,
  })),
  on(projectActions.scheduleProjectClosure, (state) => ({
    ...state,
    closureScheduling: true,
  })),
  on(projectActions.scheduleProjectClosureFailure, (state) => ({
    ...state,
    closureScheduling: false,
  })),
  on(projectActions.scheduleProjectClosureSuccess, (state, { closure }) => ({
    ...state,
    closureScheduling: false,
    scheduledClosure: closure,
  })),
  on(projectActions.setViewAsGroup, (state, { group }) => ({
    ...state,
    viewAsGroup: group,
  })),
  on(projectActions.loadProject, (state, { silent }) => ({
    ...state,
    loading: !silent,
    project: silent ? state.project : null,
  })),
  on(projectActions.loadProjectFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(projectActions.loadProjectSuccess, (state, { currentUser, project }) => ({
    ...state,
    loading: false,
    currentUser,
    project,
  })),
  on(projectActions.updateProject, (state) => ({
    ...state,
    updating: true,
  })),
  on(projectActions.updateProjectFailure, (state, { error }) => ({
    ...state,
    loading: false,
    updateProjectError: error,
    updating: false,
  })),
  on(projectActions.updateProjectSuccess, (state, { project }) => ({
    ...state,
    loading: false,
    project: { ...state.project, ...project as DetailedProject },
    updateProjectError: null,
    updating: false,
  })),
  on(projectActions.updateProjectUserSuccess, (state, { currentUser }) => ({
    ...state,
    currentUser,
  })),
  on(projectActions.loadSatisfactionSurveyVisibility, (state) => ({
    ...state,
    satisfactionSurveyLoading: true,
  })),
  on(projectActions.loadSatisfactionSurveyVisibility, (state) => ({
    ...state,
    satisfactionSurveyLoading: true,
  })),
  on(projectActions.loadSatisfactionSurveyVisibilitySuccess, (state, { visible }) => ({
    ...state,
    satisfactionSurveyLoading: false,
    satisfactionSurveyVisible: visible,
  })),
);
