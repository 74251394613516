import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { Toast, ToastModule as PrimeNgToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';

import { Shortcut, getShortcutValue } from '@ideals/services/shortcuts-manager/get-shortcut-value.util';
import { asType } from '@ideals/utils/as-type';

import { ProgressCircleComponent } from '../progress-circle';
import { TooltipEllipsisOnlyDirective } from '../tooltip-ellipsis-only';

import { ToastService } from './toast.service';

export interface ToastMessageButton {
  readonly command: () => void;
  readonly label: string;
  readonly shortcut?: Shortcut;
}

export interface ToastMessageData {
  readonly buttons: ToastMessageButton[];
  readonly progress$: Observable<number>;
}

export interface ToastMessage extends Message {
  readonly data?: ToastMessageData;
}

const TOAST_Z_INDEX = 999999999999;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    ButtonModule,
    NgClass,
    PrimeNgToastModule,
    ProgressCircleComponent,
    TooltipEllipsisOnlyDirective,
    TooltipModule,
    TranslateModule,
  ],
  providers: [MessageService],
  selector: 'ideals-toast',
  standalone: true,
  styleUrl: './toast.component.scss',
  templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit, OnDestroy {
  @Output() readonly closed = new EventEmitter<ToastMessage>();

  @ViewChild('toast', { static: true }) toast!: Toast;

  readonly #toastService = inject(ToastService);

  protected readonly asMessage = asType<ToastMessage>();
  protected readonly getShortcutValue = getShortcutValue;
  // Static z-index is needed to show toast above overlay panels
  protected readonly toastZIndex = TOAST_Z_INDEX;
  protected readonly tooltipZIndex = String(TOAST_Z_INDEX + 1);

  readonly messageService = inject(MessageService);

  ngOnInit(): void {
    this.#toastService['setToastComponent'](this);
    this.toast.onClose.subscribe(({ message }) => this.closed.emit(message as ToastMessage));
  }

  ngOnDestroy(): void {
    this.#toastService['setToastComponent']();
  }

  close(message: ToastMessage): void {
    const messageIndex = (this.toast.messages ?? []).indexOf(message);

    if (messageIndex !== -1) {
      this.toast.onMessageClose({ index: messageIndex, message });
    }
  }

  protected onButtonClick(message: ToastMessage, button: ToastMessageButton): void {
    button.command();
    this.close(message);
  }
}
