import { EnvironmentProviders, Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';

import { AuthUserStore } from './auth-user';
import { ErrorsEffects, ErrorsStore } from './errors';
import { QnaSettingsStore } from './qna-settings';

export function provideVdrStore(): (Provider | EnvironmentProviders)[] {
  return [
    AuthUserStore,
    ErrorsStore,
    provideEffects([
      ErrorsEffects,
    ]),
    // TODO: remove after https://ideals.atlassian.net/browse/PHX-7874
    QnaSettingsStore,
  ];
}
