import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[pTooltip]',
  standalone: true,
})
export class TooltipEllipsisOnlyDirective implements OnInit {
  @Input('pTooltip') text!: string;
  @Input() tooltipDisabled!: boolean;
  @Input() tooltipEllipsisOnly!: boolean;

  readonly #element = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;
  readonly #tooltip = inject(Tooltip);

  ngOnInit(): void {
    const activate = this.#tooltip.activate.bind(this.#tooltip) as () => void;

    this.#tooltip.activate = () => {
      const allowed = (!this.tooltipEllipsisOnly || this.#element.scrollWidth > this.#element.clientWidth) && !this.tooltipDisabled;

      if (allowed) {
        activate();
      }
    };
  }
}
