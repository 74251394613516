import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { HttpError, RequestError } from '../../models';

import { errorsActions } from './errors.actions';

@Injectable({ providedIn: 'root' })
export class ErrorsFacade {
  readonly #store = inject(Store);

  requestError(error: HttpError | RequestError): void {
    this.#store.dispatch(errorsActions.requestError({ error }));
  }
}
